import { userAxios } from '../../index'

const messageList = async data => {
  try {
    return await userAxios
      .post(`message-sms/list/${data.facility}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const messageCreate = async data => {
  try {
    return await userAxios.post('message-sms', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const messageUpdate = async (id, data) => {
  try {
    return await userAxios.put(`message-sms/${id}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const messageDelete = async id => {
  try {
    return await userAxios.delete(`message-sms/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  messageList,
  messageCreate,
  messageUpdate,
  messageDelete,
}
