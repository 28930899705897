<template>
  <div>
    <!-- <DiagnosticImageEdit
      v-if="isEditDiagnosticImageSidebarActive"
      :is-edit-diagnostic-image-sidebar-active.sync="isEditDiagnosticImageSidebarActive"
      :itemEdit="itemEdit"
      :optionsCategories="optionsCategories"
      @editDiagnosticImageSuccess="editDiagnosticImageSuccess" />
    <messagesFilter
      v-if="$can('filter', 'diagnostic-image-categorys')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage" /> -->
    <b-card>
      <TitleTable titleTable="Messages" />
      <div class="m-1">
        <messagesEdit
          v-if="isEditDiagnosticImageCategorySidebarActive"
          :is-edit-diagnostic-image-category-sidebar-active.sync="
            isEditDiagnosticImageCategorySidebarActive
          "
          :itemEdit="itemEdit"
          @editDiagnosticImageCategorySuccess="editDiagnosticImageCategorySuccess" />
        <messagesAdd
          v-if="isAddNewDiagnosticImageCategorySidebarActive"
          :is-add-new-diagnostic-image-category-sidebar-active.sync="
            isAddNewDiagnosticImageCategorySidebarActive
          "
          @createDiagnosticImageCategory="createDiagnosticImageCategory" />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <treeselect
              v-model="perPage"
              :options="perPageOptions"
              :clearable="true"
              class="w-25" />
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                v-if="$can('store', 'diagnostic-image-categorys')"
                variant="primary"
                @click="isAddNewDiagnosticImageCategorySidebarActive = true"
                :disabled="loading">
                <span v-if="!loading" class="text-nowrap">{{ $t('Add') }} Message</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found">
        <template #cell(actions)="data">
          <b-button
            v-if="$can('update', 'Edit Messages')"
            variant="outline-primary"
            class="btn-icon btn-circle border-0"
            v-b-tooltip.hover.top="'Edit'"
            @click="editMessages(data.item)">
            <i class="fas fa-pen icon-nm"></i>
          </b-button>

          <b-button
            v-if="$can('destroy', 'Delete Messages')"
            variant="outline-danger"
            class="btn-icon btn-circle border-0"
            v-b-tooltip.hover.top="'Delete'"
            @click="deleteMessages(data.item)">
            <i class="fas fa-trash-alt icon-nm"></i>
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import axiosAC from '@/core/services/api/admin/consults/messages'
import axiosDI from '@/core/services/api/admin/consults/diagnosticImages'

import ToastNotification from '@/components/ToastNotification'
import SpinnerLoading from '@/components/SpinnerLoading'
import PaginationTable from '@/components/PaginationTable'
import TitleTable from '@/components/TitleTable.vue'
import GoBack from '@/components/ButtonBack.vue'
import Swal from 'sweetalert2'

// import DiagnosticImageEdit from "../diagnostic-image/DiagnosticImageEdit.vue";
import messagesEdit from './messagesEdit.vue'
import messagesAdd from './messagesAdd.vue'
import messagesFilter from './messagesFilter.vue'
import { ref } from '@vue/composition-api'
export default {
  components: {
    // DiagnosticImageEdit,
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    messagesEdit,
    messagesAdd,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'id',
        { key: 'facility_name', label: 'Facility', sortable: true },
        { key: 'type_visit_name', label: 'Type visit', sortable: true },
        { key: 'type_visit_name', label: 'Type visit', sortable: true },
        { key: 'text_message', label: 'Message to send', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      items: null,
      loading: false,
      isEditDiagnosticImageCategorySidebarActive: false,
      isAddNewDiagnosticImageCategorySidebarActive: false,
      isEditDiagnosticImageSidebarActive: false,
      itemEdit: null,
      optionsCategories: [],
      optionsDiagnostic: [],
      optionsLaboratories: [],
      optionsProcedures: [],
      optionsTest: [],
      optionsTreatments: [],
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100].map(i => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      filtro: false,
      messageFilter: null,
      facility: JSON.parse(atob(localStorage.getItem('Facility'))),
    }
  },
  mounted() {
    this.getMessage()
  },
  watch: {
    // perPage(newVal) {
    //   if (!this.filtro) {
    //     this.getMessage()
    //   } else {
    //     this.searchFilter(this.messageFilter)
    //   }
    // },
  },
  methods: {
    editDiagnosticImageSuccess(value) {
      if (value) {
        this.$refs.toast.success('Message updated successfully')
      } else {
        this.$refs.toast.error('Error updating Message')
      }
      this.isEditDiagnosticImageSidebarActive = false
      this.pageChanged(this.currentPage.page)
    },
    getMessage() {
      const params = {
        columnaOrder: 'created_at',
        orderDireccion: 'DESC',
        start: 0,
        length: 10,
        facility: this.facility.id,
      }
      axiosAC.messageList(params).then(data => {
        this.items = data.data.map(item => {
          return {
            ...item,
            facility_name: item.facility.name,
            type_visit_name: item.type_visit.name,
          }
        })
      })
    },
    editMessages(item) {
      this.isEditDiagnosticImageCategorySidebarActive = true
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)))
    },
    editDiagnosticImageCategorySuccess(status) {
      if (status) {
        this.$refs.toast.success('Message updated successfully')
      } else {
        this.$refs.toast.error('Error updating Message')
      }
      this.isEditDiagnosticImageCategorySidebarActive = false
      this.pageChanged(this.currentPage.page)
    },
    createDiagnosticImageCategory({ status }) {
      if (status) {
        this.$refs.toast.success('Message added successfully')
      } else {
        this.$refs.toast.danger('Message no added by exists or attempt again')
      }
      this.isAddNewDiagnosticImageCategorySidebarActive = false
      this.pageChanged(this.currentPage.page)
    },
    deleteMessages(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axiosAC.messageDelete(item.id).then(response => {
            this.$refs.toast.success('Message type has been deleted successfully')
            this.pageChanged(this.currentPage.page)
          })
        }
      })
    },
    pageChanged(page) {
      this.getMessage()
      if (!this.filtro) {
        // axiosAC.MessagePagination(this.perPage, page).then(({ data: { data, total, current_page, ...res } }) => {
        //     this.items = data;
        //     this.totalUsers = total;
        //     this.dataMeta.of = total;
        //     this.dataMeta.from = res.from;
        //     this.dataMeta.to = res.to;
        //     this.currentPage.page = current_page;
        // });
      } else {
        // axiosAC.MessageFilterPagination(this.perPage, page, this.MessageFilter).then(({ data: { data, total, current_page, ...res } }) => {
        //     this.items = data;
        //     this.totalUsers = total;
        //     this.dataMeta.of = total;
        //     this.dataMeta.from = res.from;
        //     this.dataMeta.to = res.to;
        //     this.currentPage.page = current_page;
        // });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true
        this.messageFilter = value
        // axiosAC.MessageFilter(this.perPage, value).then(({ data: { data, total, current_page, ...res } }) => {
        //     this.loading = false;
        //     this.items = data;
        //     this.totalUsers = total;
        //     this.dataMeta.of = total;
        //     this.dataMeta.from = res.from;
        //     this.dataMeta.to = res.to;
        //     this.currentPage.page = current_page;
        // }).catch(() => {
        //     this.loading = false;
        // });
      } else {
        this.getMessage()
      }
    },
    filterPage(value) {
      this.filtro = value
    },
  },
}
</script>

<style></style>
